import React, { useState, useCallback } from 'react';
import { Tabs, Tab } from '@common/components/';
import PropTypes from 'prop-types';

const CommonTabs = ({ tabs, activeTab: controlledActiveTab, onTabChange: controlledOnTabChange, ...other }) => {
    const activeTab = controlledActiveTab || tabs[0]?.value || '';
    const [internalActiveTab, setInternalActiveTab] = useState(activeTab);
    const isControlled = controlledActiveTab !== undefined && controlledOnTabChange !== undefined;

    const handleTabChange = useCallback(
        (event, newValue) => {
            if (isControlled) {
                controlledOnTabChange(event, newValue);
            } else {
                setInternalActiveTab(newValue);
            }
        },
        // eslint-disable-next-line
        [isControlled, controlledOnTabChange]
    );

    return (
        <Tabs value={internalActiveTab} onChange={handleTabChange} {...other}>
            {tabs.map(({ label, value, icon: IconComponent = null, component: Component, to }) => (
                <Tab
                    key={value}
                    className={'py-3'}
                    label={
                        <>
                            {IconComponent && React.createElement(IconComponent, { className: 'mr-2' })}
                            {label}
                        </>
                    }
                    value={value}
                    component={Component}
                    to={to}
                />
            ))}
        </Tabs>
    );
};

CommonTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.string.isRequired,
            // icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node, PropTypes.oneOf([null, undefined])]), Allen TODO: observe
            component: PropTypes.elementType,
            to: PropTypes.string,
        })
    ).isRequired,
    activeTab: PropTypes.string,
    onTabChange: PropTypes.func,
};

export default CommonTabs;
