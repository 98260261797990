import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import MemberAvatar from '@icoach/components/MemberAvatar';
import { Box, Stack, Typography } from '@common/components/';
import PrivacyDataToggle from '@icoach/components/PrivacyDataToggle';

export const MemberInfoBox = React.memo((props) => {
    const { className, style, sourceData = {} } = props;
    const { memberName, memberNo, nickName, isSourceType = false, sourceTypeText } = sourceData;

    return (
        <Box className={className} style={style}>
            <Typography className={'member-box-name-label'} variant={'h5'} title={memberName}>
                {memberName}
            </Typography>
            {!isSourceType ? (
                <React.Fragment>
                    {nickName && (
                        <Typography className={'member-box-nickname-label'} variant={'body2'} title={nickName}>
                            {nickName}
                        </Typography>
                    )}
                    <Typography className={'member-box-other'} variant={'body2'} title={memberNo}>
                        {memberNo}
                    </Typography>
                </React.Fragment>
            ) : (
                <Typography className={'member-box-other'} variant={'body2'} color={'gray'} title={sourceTypeText}>
                    {sourceTypeText}
                </Typography>
            )}
        </Box>
    );
});

export const MemberInfoPrivacyBox = ({ className, style, sourceData, isRevealed, toggle, showIcon }) => {
    const { displayName, maskDisplayName, memberNo, sourceTypeText, isSourceType } = sourceData;
    const showMemberNo = memberNo !== undefined && memberNo !== null && memberNo !== '';
    return (
        <Box className={className} style={style}>
            <PrivacyDataToggle fullData={displayName} maskedData={maskDisplayName} isRevealed={isRevealed} onToggle={toggle} showIcon={showIcon} />
            {showMemberNo && (
                <Typography className={'member-box-other'} variant={'body2'} title={memberNo}>
                    {memberNo}
                </Typography>
            )}
            {isSourceType && (
                <Typography className={'member-box-other'} variant={'body2'} color={'gray'} title={sourceTypeText}>
                    {sourceTypeText}
                </Typography>
            )}
        </Box>
    );
};

/**
 * 組合 姓名 暱稱 會員編號 組件
 */
export const BusinessCardBox = ({
    className,
    sourceData,
    memberAvatarWidth = '5.5',
    routerPath,
    isShowAvatar = true,
    isShowAvatarImage = true,
    isPrivate = false,
    isRevealed,
    toggle,
    showIcon,
    onClick,
}) => {
    const history = useHistory();
    const { memberID, memberName, memberImg, membershipStatus } = sourceData;
    const hasRouterPath = typeof routerPath === 'function';

    const handleOnClick = () => {
        if (typeof onClick === 'function') {
            onClick(sourceData);
        }
        if (hasRouterPath) {
            const path = routerPath(memberID);
            history.push(path);
        }
    };

    return (
        <Stack
            className={clsx('business-card-box', hasRouterPath && 'cursor-pointer', className)}
            onClick={handleOnClick}
            justifyContent={'start'}
            alignItems="center"
        >
            {isShowAvatar && (
                <MemberAvatar
                    alt={memberName || 'test'}
                    src={isShowAvatarImage ? memberImg : ''}
                    width={memberAvatarWidth}
                    membershipStatus={membershipStatus}
                />
            )}

            {isPrivate ? (
                <MemberInfoPrivacyBox
                    className={clsx(isShowAvatar && 'business-card-box-content')}
                    style={{ maxWidth: isShowAvatar ? `calc(100% - ${memberAvatarWidth + 'rem'})` : '' }}
                    sourceData={sourceData}
                    isRevealed={isRevealed}
                    toggle={toggle}
                    showIcon={showIcon}
                />
            ) : (
                <MemberInfoBox
                    className={clsx(isShowAvatar && 'business-card-box-content')}
                    style={{ maxWidth: isShowAvatar ? `calc(100% - ${memberAvatarWidth + 'rem'})` : '' }}
                    sourceData={sourceData}
                />
            )}
        </Stack>
    );
};

export default BusinessCardBox;
