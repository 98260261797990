import React from 'react';
import { Grid } from '@mui/material';
import { moreSheet, contactSheet, rowLabelCheckboxSheet, SpecialConditionBox, getMemberSheet, getCellPhoneSheet } from '@icoach/components/';
import { DateIntervalField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate, getAddDays, isValidDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import { PrivacyDataToggle } from '@icoach/components';

export const FilterBirthdaySearch = React.forwardRef(({ paramsData }, ref) => {
    const dateRangeRef = React.useRef(null);

    React.useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(dateRangeRef),
                getResult: () => {
                    const { startDate, endDate } = dateRangeRef.current.getResult() || {};
                    return {
                        startDate: parseDate(startDate),
                        endDate: parseDate(endDate),
                    };
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    const year = parseDate(new Date(), 'YYYY');
    const birthDayStart = isValidDate(`${year}-${paramsData.startDate}`)
        ? `${year}-${paramsData.startDate}`
        : isValidDate(paramsData.startDate)
          ? paramsData.startDate
          : parseDate(new Date(), 'YYYY-MM-DD');
    const birthDayEnd = isValidDate(`${year}-${paramsData.endDate}`)
        ? `${year}-${paramsData.endDate}`
        : isValidDate(paramsData.endDate)
          ? paramsData.endDate
          : parseDate(getAddDays(new Date(), 14), 'YYYY-MM-DD');

    return (
        <Grid item xs={4}>
            <DateIntervalField
                ref={dateRangeRef}
                label="時間區間"
                startDateProps={{ defaultValue: birthDayStart }}
                endDateProps={{ defaultValue: birthDayEnd }}
                required
                fullWidth
            />
        </Grid>
    );
});

//********************** 生日名單 **********************//
export const getFilterBirthdayLabel = (isRevealed) => {
    return [
        {
            ...rowLabelCheckboxSheet,
            cellKey: 'memberID',
        },
        { ...getMemberSheet(isRevealed), isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
        { ...getCellPhoneSheet(isRevealed) },
        {
            cellKey: 'specialCondition',
            headerLabel: '特殊狀況',
            width: 18,
            formatCell: (specialCondition, { memberID }, { setSourceDataById }) => {
                return <SpecialConditionBox sourceData={{ memberID, value: specialCondition }} setSourceDataById={setSourceDataById} />;
            },
        },
        {
            cellKey: 'weeklyWorkoutQuantity',
            headerLabel: '本週WO',
            width: 9,
            align: 'center',
        },
        {
            cellKey: 'monthlyWorkoutFrequency',
            headerLabel: 'WO頻率',
            align: 'center',
        },
        {
            cellKey: 'birthday',
            headerLabel: '生日',
            width: 13,
            align: 'center',
            formatCell: (birthday, { maskBirthday }) => {
                return (
                    <PrivacyDataToggle fullData={parseDate(birthday, DateTimeStandard.DisplayDateBySlash)} maskedData={maskBirthday} isRevealed={isRevealed} />
                );
            },
        },
        {
            cellKey: 'address',
            headerLabel: '住址',
            className: 'white-space-normal',
            width: 24,
            formatCell: (address, { postCode, maskAddress }) => {
                const addressInfo = `${postCode} ${address}`;
                const maskAddressInfo = `${postCode} ${maskAddress}`;
                return <PrivacyDataToggle fullData={addressInfo} maskedData={maskAddressInfo} isRevealed={isRevealed} />;
            },
        },
        { ...contactSheet },
        { ...moreSheet },
    ];
};
