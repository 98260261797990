import React, { useRef } from 'react';
import { NavLink, useParams, Switch } from 'react-router-dom';
import { PrivateRoute } from '@common/components/';
import {
    CashRegister as CashRegisterIcon,
    DollarSign as DollarSignIcon,
    FileInvoiceDollar as FileInvoiceDollarIcon,
    ListUl as ListUlIcon,
} from '@common/SvgIcon/';
import AccountBookOrderOverviewPage from '@icoach/accountBook/AccountBookOrderOverviewPage';
import AccountBookOrderStatOnSitePage from '@icoach/accountBook/AccountBookOrderStatOnSitePage';
import AccountBookOrderStatOnAllPage from '@icoach/accountBook/AccountBookOrderStatOnAllPage';
import AccountBookContentRemitOrderOverview from '@icoach/accountBook/AccountBookRemitOverviewPage';
import BatchPaymentManagementPage from '@icoach/accountBook/BatchPaymentManagementPage';
import {
    ACCOUNT_BOOK_ORDER_CREATE,
    ACCOUNT_BOOK_REMIT_CREATE,
    ACCOUNT_BOOK_ORDERS_OVERVIEW_URL,
    ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL,
    ACCOUNT_BOOK_BULK_DEBIT_URL,
    ACCOUNT_BOOK_SITE_ORDER_STAT_URL,
    ACCOUNT_BOOK_ALL_ORDER_STAT_URL,
} from '@icoach/router/AccountBookRouter';
import AccountBookDialogBox from '@icoach/accountBook/dialog/AccountBookDialogBox';
import PageLayout from '@icoach/components/layout/PageLayout';
import useMenusControlled from '@util/hook/useMenusControlled';

const pageTabsMap = {
    orders: 'orders',
    remitOrders: 'remit-orders',
    siteOrderStat: 'site-order-stat',
    allOrderStat: 'all-order-stat',
    bulkDebit: 'bulk-debit',
};

const AccountBookPage = () => {
    const { tabs: activeTab } = useParams();
    const dialogRef = useRef(null);
    const pageTabs = useMenusControlled([
        {
            label: '收入總覽',
            value: pageTabsMap.orders,
            icon: ListUlIcon,
            component: NavLink,
            frontKey: 'C6X02',
            to: ACCOUNT_BOOK_ORDERS_OVERVIEW_URL,
        },
        {
            label: '匯款總覽',
            value: pageTabsMap.remitOrders,
            icon: ListUlIcon,
            component: NavLink,
            frontKey: 'C6X07',
            to: ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL,
        },
        {
            label: '現場收入總覽',
            value: pageTabsMap.siteOrderStat,
            icon: CashRegisterIcon,
            component: NavLink,
            frontKey: 'C6X03',
            to: ACCOUNT_BOOK_SITE_ORDER_STAT_URL,
        },
        {
            label: '總收入總覽',
            value: pageTabsMap.allOrderStat,
            icon: DollarSignIcon,
            component: NavLink,
            frontKey: 'C6X04',
            to: ACCOUNT_BOOK_ALL_ORDER_STAT_URL,
        },
        {
            label: '批次扣款',
            value: pageTabsMap.bulkDebit,
            icon: FileInvoiceDollarIcon,
            component: NavLink,
            frontKey: 'C6X05',
            to: ACCOUNT_BOOK_BULK_DEBIT_URL,
        },
    ]);
    const pageActions = useMenusControlled([
        {
            label: '新增匯款',
            variant: 'outlined',
            frontKey: 'C6X08',
            as: NavLink,
            to: ACCOUNT_BOOK_REMIT_CREATE,
        },
        {
            label: '新增收入',
            variant: 'contained',
            frontKey: 'C6X06',
            as: NavLink,
            to: ACCOUNT_BOOK_ORDER_CREATE,
        },
    ]);

    return (
        <PageLayout>
            <PageLayout.Tabs tabs={pageTabs} activeTab={activeTab} actions={pageActions} />
            <Switch>
                <PrivateRoute path={ACCOUNT_BOOK_ORDERS_OVERVIEW_URL} as={AccountBookOrderOverviewPage} dialogRef={dialogRef} exact />
                <PrivateRoute path={ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL} as={AccountBookContentRemitOrderOverview} dialogRef={dialogRef} exact />
                <PrivateRoute path={ACCOUNT_BOOK_SITE_ORDER_STAT_URL} as={AccountBookOrderStatOnSitePage} dialogRef={dialogRef} exact />
                <PrivateRoute path={ACCOUNT_BOOK_ALL_ORDER_STAT_URL} as={AccountBookOrderStatOnAllPage} dialogRef={dialogRef} exact />
                <PrivateRoute path={ACCOUNT_BOOK_BULK_DEBIT_URL} as={BatchPaymentManagementPage} dialogRef={dialogRef} />
            </Switch>
            <AccountBookDialogBox ref={dialogRef} />
        </PageLayout>
    );
};

export default AccountBookPage;