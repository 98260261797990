import React, { useImperativeHandle, useRef } from 'react';
import { Grid } from '@mui/material';
import { moreSheet, contactSheet, NotesLabelBox, rowLabelCheckboxSheet, SpecialConditionBox, getMemberSheet, getCellPhoneSheet } from '@icoach/components/';
import { DateField, Typography } from '@common/components/';
import { refIsRequiredError, isNumber } from '@util/utils';
import { displayDateText, parseDate, isValidDate } from '@util/moment';
import { MeasureDayRangeField } from '@icoach/inbody/overview/FilterUnInBodyScannerTemplate';

// 已量身名單
export const FilterInBodyScannerSearch = React.forwardRef(({ paramsData }, ref) => {
    const dateMonthRef = useRef(null);
    const measureDayRangeRef = useRef(null);

    useImperativeHandle(ref, () => {
        return {
            isError: () => refIsRequiredError(dateMonthRef, measureDayRangeRef),
            getResult: () => {
                let result = {};

                if (dateMonthRef.current && dateMonthRef.current.getResult) {
                    Object.assign(result, { date: parseDate(dateMonthRef.current.getResult(), 'YYYY-MM') });
                }

                if (measureDayRangeRef.current && measureDayRangeRef.current.getResult) {
                    Object.assign(result, measureDayRangeRef.current.getResult());
                }

                return result;
            },
        };
    });

    const dateMonth = !!paramsData.date && isValidDate(`${paramsData.date}-01`) ? `${paramsData.date}-01` : parseDate(new Date(), 'YYYY-MM-DD');
    const maxDay = isNumber(paramsData.measureEndDay) ? paramsData.measureEndDay * 1 : 31;
    const minDay = isNumber(paramsData.measureStartDay) ? paramsData.measureStartDay * 1 : 1;

    return (
        <React.Fragment>
            <Grid xs={3} item>
                <DateField
                    defaultValue={dateMonth}
                    ref={dateMonthRef}
                    views={['year', 'month']}
                    openTo="month"
                    format="YYYY/MM"
                    label="月份"
                    mask="____/__"
                    fullWidth
                />
            </Grid>
            <Grid xs={3} item>
                <MeasureDayRangeField
                    ref={measureDayRangeRef}
                    label={'WM日'}
                    defaultValue={{
                        maxDay,
                        minDay,
                    }}
                    fullWidth
                />
            </Grid>
        </React.Fragment>
    );
});

export const getFilterInBodyScannerLabel = (isRevealed) => {
    return [
        {
            ...rowLabelCheckboxSheet,
            cellKey: 'memberID',
        },
        { ...getMemberSheet(isRevealed), isFixed: true, isFixedLeft: true },
        { ...getCellPhoneSheet(isRevealed) },
        {
            cellKey: 'specialCondition',
            headerLabel: '特殊狀況',
            width: 18,
            formatCell: (specialCondition, { memberID }, { setSourceDataById }) => {
                return <SpecialConditionBox sourceData={{ memberID, value: specialCondition }} setSourceDataById={setSourceDataById} />;
            },
        },
        {
            cellKey: 'measureDay',
            headerLabel: 'WM日',
            width: 9,
            align: 'center',
        },
        {
            cellKey: 'lastMeasureDate',
            headerLabel: '最近一次量身',
            width: 17,
            align: 'center',
            formatCell: (_value) => displayDateText(_value),
        },
        {
            cellKey: 'interviewEmployeeName',
            headerLabel: '面談擔當',
            align: 'center',
        },
        {
            cellKey: 'xFactorNotes',
            headerLabel: 'X-factor',
            className: 'white-space-normal',
            width: 30,
            formatCell: (value) => (
                <React.Fragment>
                    {Array.isArray(value) ? (
                        <React.Fragment>
                            {value.map((target) => (
                                <Typography key={target}>{target}</Typography>
                            ))}
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            ),
        },
        {
            cellKey: 'interviewContent',
            headerLabel: '面談內容',
            width: 30,
            maxWidth: 30,
            align: 'left',
            formatCell: (value) => <NotesLabelBox title="面談內容" msg={value} />,
        },
        { ...contactSheet },
        { ...moreSheet },
    ];
};
