import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { Button, Form, MenuItem, SelectField, Stack, TextField, Box } from '@common/components/';
import { PasswordAgeWarningTextBox } from '@root/scripts/login/component/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { ReadTextField } from '@icoach/components';
import useGetEmployeeOptionApi from '@util/hook/useGetEmployeeOptionApi';
import { generateSecurity } from '@util/apiCryto';
import useAuthUserApi from '@apis/useAuthUserApi';
import useAuthApi from '@apis/useAuthApi';
import { ROOT__ROUTER, LOGIN_COACH_RESET_PASSWORD_TOKEN_PATH } from '@icoach/router/routerPath';
import { localUser, isEmpty } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';

const EmployeeLoginDialog = (props) => {
    const { option: employeeOption } = useGetEmployeeOptionApi();
    const { postLoginEmployeeApi, postEmployeeNoChangeAuthPwdApi } = useAuthUserApi();
    const { getLogoutApi } = useAuthApi();
    const setMessageDialog = useMessageDialog();
    const history = useHistory();
    const user = localUser.get();
    const userRef = useRef(null);
    const passwordRef = useRef(null);

    const getParams = () => {
        const id = userRef.current.getResult();
        const paswd = passwordRef.current.getResult();
        const { hmacSecurity, hmacKey } = generateSecurity(paswd);
        return {
            employeeID: id,
            password: hmacSecurity,
            hmacKey,
        };
    };

    // 修改密碼視窗
    const notificationMessage = (token) => {
        setMessageDialog({
            open: true,
            dialogPaperProps: { className: 'wx-65rem' },
            title: '通知',
            buttonText: '此次不變更',
            DialogActionsProps: {
                className: 'flex-y-flex-center',
            },
            buttonVariant: 'text',
            onClose: () => {
                // 直接進入首頁
                history.push(ROOT__ROUTER);
            },
            onAsyncConfirm: (_e, onClose) => {
                postRecordApi(token, onClose);
            },
            MsgComp: ({ onClose }) => {
                return (
                    <React.Fragment>
                        <PasswordAgeWarningTextBox />
                        <Stack justifyContent="center">
                            <Button
                                onClick={() => {
                                    onClose();
                                    jumpToModifyPasswordAction(token);
                                }}
                                variant="contained"
                            >
                                前往修改密碼
                            </Button>
                        </Stack>
                    </React.Fragment>
                );
            },
        });
    };

    const jumpToModifyPasswordAction = async (token) => {
        history.push(LOGIN_COACH_RESET_PASSWORD_TOKEN_PATH(token));
    };

    const handleSubmit = () => {
        const params = getParams();
        postEmployeeLoginApi(params);
    };

    const handleBackToClub = () => {
        getLogoutClubApi();
    };

    const getLogoutClubApi = async () => {
        await getLogoutApi();
    };

    const postRecordApi = async (token, onClose) => {
        const resp = await postEmployeeNoChangeAuthPwdApi(token);
        if (resp) {
            // 直接進入首頁
            history.push(ROOT__ROUTER);
            onClose();
        }
    };

    const postEmployeeLoginApi = async (params) => {
        const resp = await postLoginEmployeeApi(params);
        if (resp) {
            localUser.setEmployeeData(resp);
            if (resp.isPasswordResetRequired) {
                history.push(LOGIN_COACH_RESET_PASSWORD_TOKEN_PATH(resp.token)); // 帶token過去 透過這個來判斷教練
            } else if (resp.isPasswordResetNotify) {
                notificationMessage(resp.token);
            } else {
                // 切換到首頁
                history.push(ROOT__ROUTER);
            }
        }
    };

    const doInitialization = () => {
        const employee = localUser.get('employee');
        if (!isEmpty(employee)) {
            history.push(ROOT__ROUTER);
        }
    };

    useEffect(
        () => {
            doInitialization();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', 'user-login-dialog')}
            PaperProps={{ className: 'wx-70rem', component: Form }}
            BackdropProps={{
                style: {
                    backgroundColor: '#797a7c',
                },
            }}
            onSubmit={handleSubmit}
            open={true}
            fullWidth
        >
            <GradientColorHeader>教練登入</GradientColorHeader>
            <DialogContent>
                <Box className={'user-login-dialog__wrapper'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ReadTextField label={'店舖名：'}>{user.displayName}</ReadTextField>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectField label="當前教練" ref={userRef} fullWidth required>
                                {Array.isArray(employeeOption) &&
                                    employeeOption.map(({ value, text, disabled }) => (
                                        <MenuItem key={value} value={value} disabled={disabled}>
                                            {text}
                                        </MenuItem>
                                    ))}
                            </SelectField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="密碼" ref={passwordRef} type="password" fullWidth required />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack className={'mt-2'} justifyContent={'center'} spacing={3}>
                                <Button variant="contained" type="submit">
                                    確定
                                </Button>
                                <Button variant="outlined" onClick={handleBackToClub}>
                                    離開店舖
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default EmployeeLoginDialog;
