import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, upperCase } from 'lodash';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { getVersionData } from '@redux/version/selector';
import NavigationPage from '@icoach/navigation/NavigationPage';
import Main from '@icoach/main/Main';
import { getIsAndroid, flatDeepArray, localUser } from '@util/utils';
import ReactGA from 'react-ga4';
import useNavigationApi from '@apis/useNavigationApi';
import useAppSystemApi from '@apis/useAppSystemApi';
import { setMenusData } from '@redux/menus/action';
import { setVersionData } from '@redux/version/action';
import useShowLoading from '@util/hook/useShowLoading';
import { ROOT_ROUTER, ROOT_NOTIFICATIONS_ROUTER } from '@icoach/router/routerPath';
import { PrivateRoute } from '@common/components/';
import NotificationNavBox from '@icoach/notifications/NotificationNavBox';
import useMessageDialog from '@util/hook/useMessageDialog';
import useInactivityTimer from '@util/hook/useInactivityTimer';
import useAuthUserApi from '@apis/useAuthUserApi';
import useAuthApi from '@apis/useAuthApi';


const Home = (props) => {
    const { path: rootPath } = props;
    const showLoading = useShowLoading();
    const { getWebClubInfoApi } = useAppSystemApi();
    const { getMeMenusApi } = useNavigationApi();
    const _dispatch = useDispatch();
    const reduxVersion = useSelector(getVersionData);
    const setMessageDialog = useMessageDialog();
    const { deleteEmployeeAuthPwdApi } = useAuthUserApi();
    const { getLogoutApi } = useAuthApi();

    const inactivityTimerConfig = useMemo(
        () => {
            const isDevEnv = reduxVersion?.env?.toUpperCase() === 'DEV';
            const isProductionEnv = reduxVersion?.env?.toUpperCase() === 'PRODUCTION';
            const isUatEnv = reduxVersion?.env?.toUpperCase() === 'UAT';
            let firstTimeout = 5 * 60 * 1000;
            let secondTimeout = 30 * 1000;

            if (isProductionEnv || isUatEnv) {
                firstTimeout = 30 * 60 * 1000;
                secondTimeout = 3 * 60 * 1000;
            }

            const logOutApi = async () => {
                const employee = localUser.get('employee');
                if (!isEmpty(employee)) {
                    await deleteEmployeeAuthPwdApi(employee.tokoen);
                } else {
                    await getLogoutApi();
                }
            };

            return {
                firstTimeout,
                secondTimeout,
                onFirstTimeout: (resetFirstTimer) => {
                    isDevEnv && console.log('第一次時間到', new Date().toLocaleString());
                    setMessageDialog({
                        open: true,
                        title: '重要提醒',
                        msg: '系統偵測到您已超過30分鐘未進行操作，是否需要繼續使用？若無操作，將於3分鐘後登出教練權限。',
                        buttonText: '確認',
                        onConfirm: () => {
                            resetFirstTimer();
                        },
                    });
                },
                onSecondTimeout: () => {
                    isDevEnv && console.log('第二次時間到', new Date().toLocaleString());
                    setMessageDialog({
                        open: false,
                    });
                    logOutApi();
                },
            };
        },
        // eslint-disable-next-line
        [setMessageDialog, deleteEmployeeAuthPwdApi, getLogoutApi, reduxVersion],
    );

    useInactivityTimer(inactivityTimerConfig);

    const getInitData = async () => {
        showLoading(true);
        const menuData = await getMeMenusApi();
        const versionData = await getWebClubInfoApi();
        if (menuData) {
            const { menus = [], clubs = [] } = menuData;
            if (clubs[0]) localUser.setClubData(clubs[0]);
            _dispatch(setMenusData({ menulist: flatDeepArray(menus), oriMenuData: menus }));
        }
        if (versionData) {
            _dispatch(setVersionData(versionData));
        }
        showLoading(false);
    };

    useEffect(
        () => {
            if (!isEmpty(reduxVersion) && reduxVersion?.env) {
                const { env } = reduxVersion;
                if (upperCase(env) === 'PRODUCTION') {
                    ReactGA.initialize('G-RBG571BKB5');
                } else if (upperCase(env) === 'UAT') {
                    ReactGA.initialize('G-JSYHPF7NF2');
                }
            }
        },
        // eslint-disable-next-line
        [reduxVersion]
    );

    useEffect(
        () => {
            getInitData();
            if (getIsAndroid()) {
                document.body.classList.add('mobile');
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Router>
            <div className={`icoach`}>
                <Switch>
                    <PrivateRoute path={ROOT_ROUTER} as={NavigationPage} />
                    <PrivateRoute path={ROOT_NOTIFICATIONS_ROUTER} as={NotificationNavBox} />
                </Switch>
                <Main path={rootPath} className={'main-container'} />
            </div>
        </Router>
    );
};

export default Home;
