import { Grid } from '@mui/material';
import {
    contactSheet,
    getCellPhoneSheet,
    getMemberSheet,
    moreSheet,
    MultipleSelectCheckedBox,
    rowLabelCheckboxSheet,
    SpecialConditionBox,
} from '@icoach/components/';
import React, { useImperativeHandle, useRef } from 'react';
import { refIsRequiredError, checkRefFuncExists, findIndexFn, isEmpty } from '@util/utils';
import { MenuItem, SelectField, Typography } from '@common/components/';

//********************** 健康產品名單 **********************//
export const FilterHealthCareOrderSearch = React.forwardRef((props, ref) => {
    const { resource = {}, paramsData } = props;
    const {
        membershipStatusOption: membershipStatusOptions = [],
        saleCategoryOption: saleCategoryOptions = [],
        cycleOrderStatusOption: cycleOrderStatusOptions = [],
    } = resource;
    const membershipStatusRef = useRef(null);
    const saleCategoryRef = useRef(null);
    const cycleOrderStatusRef = useRef(null);
    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(membershipStatusRef, cycleOrderStatusRef),
                getResult: () => {
                    let result = {};
                    if (checkRefFuncExists('getResult', membershipStatusRef, saleCategoryRef, cycleOrderStatusRef)) {
                        result = Object.assign(result, {
                            membershipStatus: membershipStatusRef.current.getResult(),
                            cycleOrderStatus: cycleOrderStatusRef.current.getResult(),
                            saleCategory: saleCategoryRef.current.getResult(),
                        });
                    }
                    return result;
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    const cycleOrderStatus = findIndexFn(cycleOrderStatusOptions, 'value', paramsData.cycleOrderStatus) !== -1 ? paramsData.cycleOrderStatus : 99;
    const membershipStatus = findIndexFn(membershipStatusOptions, 'value', paramsData.membershipStatus) !== -1 ? paramsData.membershipStatus : 99;
    const saleCategory = isEmpty(paramsData.saleCategory) ? {} : paramsData.saleCategory.split(',').reduce((acc, cur) => ({ ...acc, [cur]: true }), {});

    return (
        <React.Fragment>
            <Grid item xs={2}>
                <SelectField defaultValue={membershipStatus} label={'會籍狀態'} ref={membershipStatusRef} fullWidth>
                    {Array.isArray(membershipStatusOptions) &&
                        membershipStatusOptions
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
            <Grid item xs={2}>
                <MultipleSelectCheckedBox ref={saleCategoryRef} label="商品" options={saleCategoryOptions} saleCategoryValue={saleCategory} showSelectAll />
            </Grid>
            <Grid item xs={2}>
                <SelectField defaultValue={cycleOrderStatus} label={'訂單狀態'} ref={cycleOrderStatusRef} fullWidth>
                    {Array.isArray(cycleOrderStatusOptions) &&
                        cycleOrderStatusOptions
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

//********************** 健康產品名單 **********************//
export const getFilterHealthCareOrderLabel = (isRevealed) => {
    return [
        {
            ...rowLabelCheckboxSheet,
            cellKey: 'memberID',
        },
        { ...getMemberSheet(isRevealed), isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
        { ...getCellPhoneSheet(isRevealed) },
        {
            cellKey: 'specialCondition',
            headerLabel: '特殊狀況',
            width: 18,
            formatCell: (specialCondition, { memberID }, { setSourceDataById }) => {
                return <SpecialConditionBox sourceData={{ memberID, value: specialCondition }} setSourceDataById={setSourceDataById} />;
            },
        },
        {
            cellKey: 'proteinStatus',
            headerLabel: '蛋白訂單狀態',
            align: 'center',
        },
        {
            cellKey: 'proteinReason',
            headerLabel: '蛋白暫停/退訂原因',
            width: 17,
            align: 'center',
            formatCell: (cellValue, rowData) => {
                let textMemoAry = [];
                if (typeof cellValue === 'string') {
                    textMemoAry = [cellValue];
                } else if (Array.isArray(cellValue)) {
                    textMemoAry = [...cellValue];
                }
                return textMemoAry.map((text) => (
                    <Typography key={text} component="div">
                        {text}
                    </Typography>
                ));
            },
        },
        {
            cellKey: 'probioticsStatus',
            headerLabel: '益生菌訂單狀態',
            width: 15,
            align: 'center',
        },
        {
            cellKey: 'probioticsReason',
            headerLabel: '益生菌暫停/退訂原因',
            width: 19,
            align: 'center',
            formatCell: (cellValue, rowData) => {
                let textMemoAry = [];
                if (typeof cellValue === 'string') {
                    textMemoAry = [cellValue];
                } else if (Array.isArray(cellValue)) {
                    textMemoAry = [...cellValue];
                }
                return textMemoAry.map((text) => (
                    <Typography key={text} component="div">
                        {text}
                    </Typography>
                ));
            },
        },
        {
            cellKey: 'calciumStatus',
            headerLabel: '鈣訂單狀態',
            width: 15,
            align: 'center',
        },
        {
            cellKey: 'calciumReason',
            headerLabel: '鈣暫停/退訂原因',
            width: 19,
            align: 'center',
            formatCell: (cellValue, rowData) => {
                let textMemoAry = [];
                if (typeof cellValue === 'string') {
                    textMemoAry = [cellValue];
                } else if (Array.isArray(cellValue)) {
                    textMemoAry = [...cellValue];
                }
                return textMemoAry.map((text) => (
                    <Typography key={text} component="div">
                        {text}
                    </Typography>
                ));
            },
        },
        {
            cellKey: 'membershipStatusText',
            headerLabel: '會籍狀態',
            width: 9,
            align: 'center',
        },
        {
            cellKey: 'weeklyWorkoutQuantity',
            headerLabel: '本週WO',
            width: 9,
            align: 'center',
        },
        {
            cellKey: 'monthlyWorkoutFrequency',
            headerLabel: 'WO頻率',
            align: 'center',
        },
        { ...contactSheet },
        { ...moreSheet },
    ];
};
