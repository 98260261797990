import React, { useEffect, useState } from 'react';
import { Box } from '@common/components/';
import clsx from 'clsx';
import SpecialConditionDialog from '../members/memberDetail/dialog/SpecialConditionDialog';
import { Edit as EditIcon } from '@common/SvgIcon/';

const SpecialConditionBox = (props) => {
    const { className, textEllipsisColumns = 2, width = '18rem', isTableSpace = true, sourceData } = props;
    const { memberID, value } = sourceData;
    const [specialCondition, setSpecialCondition] = useState('');
    const [open, setOpen] = React.useState(false);
    const whiteSpaceNormal = isTableSpace ? 'white-space-normal' : '';

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const doRefresh = (_, { notes }) => {
        setSpecialCondition(notes);
        const { setSourceDataById } = props;
        if (typeof setSourceDataById === 'function') {
            setSourceDataById(memberID, { specialCondition: notes });
        }
    };

    useEffect(
        () => {
            if (value || value !== specialCondition) {
                setSpecialCondition(value);
            }
            return () => {
                setOpen(false);
                setSpecialCondition('');
            };
        },
        // eslint-disable-next-line
        [value],
    );

    return (
        <>
            <Box
                className={clsx(['special-condition-box', 'cursor-pointer', `text-ellipsis-columns-${textEllipsisColumns}`, className])}
                style={{ width }}
                onClick={handleOpenDialog}
            >
                <Box className={clsx(whiteSpaceNormal)}>
                    {specialCondition.length > 0 ? specialCondition : <EditIcon className={'special-condition-box__icon'} htmlColor={'#d3d2d1'} />}
                </Box>
            </Box>
            <SpecialConditionDialog open={open} onClose={handleCloseDialog} memberID={memberID} data={specialCondition} refresh={doRefresh} />
        </>
    );
};

export default SpecialConditionBox;
