import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';
import {
    GET_AUTH_USER_CLUB_PATH_API,
    PUT_AUTH_USER_SECURITY_API,
    POST_AUTH_USER_AGENT_OWNER_API,
    POST_AUTH_USER_ME_EMPLOYEE_API,
    PUT_AUTH_USER_ME_CHANGE_PASSWORD_TICKET_API,
    DELETE_AUTH_USER_ME_EMPLOYEE_TICKET_API,
    PUT_AUTH_USER_ME_EMPLOYEE_FORGOT_PASSWORD_API,
    GET_AUTH_USER_ME_CHECK_FORGOT_PASSWORD_TICKET_API,
    POST_AUTH_USER_ME_UNLOCK_SCREEN,
    POST_AUTH_USER_ME_NON_CHANGE_PASSWORD_TICKET,
} from '@apis/apiPath';
import { localUser } from '@util/utils';
import { clearFocusMode } from '@util/focusModeController';

const useAuthUserApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得登入使用者的店舖資料
    const getAuthClubApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_AUTH_USER_CLUB_PATH_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 設定次代理權限者
    const postAuthAgentOwnerApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_AUTH_USER_AGENT_OWNER_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改店鋪密碼
    const putModifyAuthPwdApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_AUTH_USER_SECURITY_API, params);
            if (!resp) {
                enqueueSnackbar('錯誤，請再操作一次', { variant: 'error' });
            } else {
                enqueueSnackbar('變更密碼成功，請重新登入', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 教練登入
    const postLoginEmployeeApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_AUTH_USER_ME_EMPLOYEE_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 教練密碼再次登入修改
    const putAuthPwdApi = useCallback(
        async (params, ticket) => {
            const resp = await Ajax.put(PUT_AUTH_USER_ME_CHANGE_PASSWORD_TICKET_API(ticket), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 教練登出
    const deleteEmployeeAuthPwdApi = useCallback(
        async (ticket) => {
            localUser.deleteEmployee();
            clearFocusMode();
            await Ajax.delete(DELETE_AUTH_USER_ME_EMPLOYEE_TICKET_API(ticket));
            window.location.reload();
        },
        // eslint-disable-next-line
        []
    );

    // 記錄教練不修改密碼
    const postEmployeeNoChangeAuthPwdApi = useCallback(
        async (ticket) => {
            return await Ajax.post(POST_AUTH_USER_ME_NON_CHANGE_PASSWORD_TICKET(ticket));
        },
        // eslint-disable-next-line
        []
    );

    // 重新設定教練密碼
    const putEmployeeForgotPasswordApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_AUTH_USER_ME_EMPLOYEE_FORGOT_PASSWORD_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postAuthUserUnlockScreenApi = useCallback(
        async (params) => {
            return await Ajax.post(POST_AUTH_USER_ME_UNLOCK_SCREEN, params);
        },
        // eslint-disable-next-line
        []
    );

    // 檢查重設店鋪密碼是否有效
    const getCheckAuthUserForgotPasswordTokenApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_AUTH_USER_ME_CHECK_FORGOT_PASSWORD_TICKET_API(params));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getAuthClubApi,
        postAuthAgentOwnerApi,
        putModifyAuthPwdApi,
        postLoginEmployeeApi,
        putAuthPwdApi,
        deleteEmployeeAuthPwdApi,
        putEmployeeForgotPasswordApi,
        getCheckAuthUserForgotPasswordTokenApi,
        postAuthUserUnlockScreenApi,
        postEmployeeNoChangeAuthPwdApi,
    };
};

export default useAuthUserApi;
