import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Card, Form } from '@common/components/';

const OverviewListSearchBar = ({ className, children, onSubmit, isResetEnabled = false, isDownloadEnabled = false, ...rest }) => {
    // const [key, setKey] = useState(uuid());
    //
    // const handleClearSearch = (e) => {
    //     if (typeof onClearSearch === 'function') onClearSearch(e);
    //     setKey(uuid());
    // };

    return (
        <Card className={className} {...rest}>
            <Card.CardContent className={'p-3'}>
                <Form onSubmit={onSubmit}>
                    <React.Fragment
                    // key={key}
                    >
                        {children}
                    </React.Fragment>
                    <Stack className={'mt-2'} justifyContent={'end'} spacing={2}>
                        {/*<Button onClick={handleClearSearch}>重設</Button>*/}
                        <Button type={'submit'} variant={'contained'}>
                            搜尋
                        </Button>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

OverviewListSearchBar.prototype = {
    className: PropTypes.string,
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    isResetEnabled: PropTypes.bool,
    isDownloadEnabled: PropTypes.bool,
};

export default OverviewListSearchBar;
