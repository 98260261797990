import React from 'react';
import { moreSheet, contactSheet, rowLabelCheckboxSheet, SpecialConditionBox, getMemberSheet, getCellPhoneSheet } from '@icoach/components/';
import { formatCurrencyFn } from '@util/utils';
import { Typography } from '@common/components/';
import { getOptionData } from '@icoach/members/staticData';

// 欠費名單
export const getFilterDebtLabel = (isRevealed) => {
    return [
        {
            ...rowLabelCheckboxSheet,
            cellKey: 'memberID',
        },
        { ...getMemberSheet(isRevealed), isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
        { ...getCellPhoneSheet(isRevealed) },
        {
            cellKey: 'specialCondition',
            headerLabel: '特殊狀況',
            width: 18,
            formatCell: (specialCondition, { memberID }, { setSourceDataById }) => {
                return <SpecialConditionBox sourceData={{ memberID, value: specialCondition }} setSourceDataById={setSourceDataById} />;
            },
        },
        {
            cellKey: 'membershipStatusText',
            headerLabel: '會籍狀態',
            align: 'center',
        },
        {
            cellKey: 'overdraftTotalPrice',
            headerLabel: '累積欠款',
            align: 'right',
            formatCell: (value, { memberID }, { onOrderDialogClick }) => {
                return (
                    <Typography className="cursor-pointer" onClick={() => onOrderDialogClick(memberID)}>
                        {formatCurrencyFn(value)}
                    </Typography>
                );
            },
        },
        { ...contactSheet },
        { ...moreSheet },
    ];
};
export const filterDebtSortOption = [
    getOptionData('會員狀態遞增', '1'),
    getOptionData('會員狀態遞減', '2'),
    getOptionData('累積欠款遞增', '3'),
    getOptionData('累積欠款遞減', '4'),
];
